<template>
  <v-card>
    <v-card-title>{{ $router.currentRoute.params.id ? 'Edit' : 'New' }} Waste Report</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="mr-2"
        :disabled="saving"
        @click="save"
      >
        Save
      </v-btn>
      <v-btn
        v-if="$router.currentRoute.params.id"
        color="secondary"
        class="mr-2"
        outlined
        @click="exportPdf"
      >
        <v-icon
          size="17"
          class="me-1"
        >
          {{ icons.mdiExportVariant }}
        </v-icon>
        Export PDF
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-fade-transition>
        <v-alert
          v-model="showAlert"
          border="left"
          :color="alertType"
          dark
          text
          dismissible
        >
          {{ alertType === 'success' ? 'Job order saved.' : 'Please check all the required fields.' }}
        </v-alert>
      </v-fade-transition>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-menu
              v-model="reportDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="wasteReportForm.report_date"
                  label="Report Date"
                  :prepend-icon="icons.mdiCalendarCheckOutline"
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="wasteReportForm.report_date"
                color="primary"
                @input="reportDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="wasteReportForm.storage"
              label="Storage"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="wasteReportForm.department"
              placeholder="Search.. ."
              :loading="loadingDepartment"
              :error-messages="errors.department"
              :items="departmentOptions"
              :search-input.sync="searchDepartment"
              cache-items
              hide-details="auto"
              label="Department"
              item-text="name"
              outlined
              dense
              hide-no-data
              return-object
              @keyup.enter="fetchDepartments"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="wasteReportForm.control_number"
              label="Control No"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <template>
      <v-tabs
        v-model="vehicleTab"
        fixed-tabs
      >
        <v-tab>
          <h4>Waste Materials ({{ wasteReportForm.waste_materials.length }})</h4>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="vehicleTab">
        <v-tab-item>
          <v-card>
            <v-card-text>
              <p
                v-if="errors.waste_materials"
                class="error--text ma-5 text-center"
                style="border-top: 2px solid #ff4c51;"
              >
                Waste Material(s) required.
              </p>
              <div class="add-services-form pb-10 px-8">
                <div
                  v-for="(material, materialIndex) in wasteReportForm.waste_materials"
                  :key="materialIndex"
                  class="single-service-form"
                  style="margin-top: 1rem;"
                >
                  <div class="add-services-header mb-2 d-none d-md-flex">
                    <div class="px-5 flex-grow-1 font-weight-semibold">
                      <v-row>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <span>Quantity</span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <span>Unit Cost</span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                        >
                          <span>Description</span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <span>O.R. No</span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <span>O.R. Amount</span>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="header-spacer"></div>
                  </div>
                  <v-card
                    outlined
                    class="d-flex"
                  >
                    <!-- Left Form -->
                    <div class="pa-5 flex-grow-1">
                      <v-row>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-text-field
                            v-model="material.quantity"
                            outlined
                            dense
                            clearable
                            type="number"
                            hide-details="auto"
                            @input="val => { material.quantity = Math.abs(val) }"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-text-field
                            v-model="material.unit_price"
                            outlined
                            dense
                            clearable
                            type="number"
                            hide-details="auto"
                            maxlength="8"
                            @input="val => { material.unit_price = Math.abs(val) }"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="material.description"
                            outlined
                            dense
                            clearable
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-text-field
                            v-model="material.sales_or_number"
                            outlined
                            dense
                            clearable
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-text-field
                            v-model="material.sales_amount"
                            outlined
                            dense
                            clearable
                            maxlength="8"
                            type="number"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <v-textarea
                            v-model="material.unit_issue"
                            label="Unit Issue"
                            rows="1"
                            outlined
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </div>
                    <!-- Service Actions -->
                    <div class="d-flex flex-column item-actions rounded-0 pa-1">
                      <v-btn
                        icon
                        small
                        @click="wasteReportForm.waste_materials.splice(materialIndex, 1)"
                      >
                        <v-icon size="20">
                          {{ icons.mdiClose }}
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                    </div>
                  </v-card>
                </div>
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    offset="4"
                  >
                    <v-btn
                      color="primary"
                      class="mt-4 col-4"
                      outlined
                      block
                      @click="addNewMaterial"
                    >
                      Add Waste Material
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCalendarCheckOutline, mdiExportVariant, mdiSpeedometer, mdiClose, mdiCogOutline } from '@mdi/js'
import axios from '@axios'
import router from '@/router'
import useWasteReports from './useWasteReports'
import useDepartments from '../job-orders/useDepartments'

const today = new Date()
const formInit = () => ({
  id: null,
  control_number: null,
  report_date: today.toISOString().substr(0, 10),
  waste_materials: [],
  department: null,
  quantity: 1,
  unit_price: 0,
  unit_issue: null,
  description: null,
  sales_or_number: null,
  sales_amount: null,
})

export default {
  setup() {
    const saving = ref(false)
    const vehicleTab = ref(null)
    const errors = ref([])
    const reportDatePicker = ref(false)
    const completionDatePicker = ref(false)
    const wasteReportForm = ref(formInit())
    const { getWasteReport } = useWasteReports()
    const resetForm = () => {
      wasteReportForm.value = formInit()
    }

    const {
      departments: departmentOptions,
      getDepartments,
      searchDepartment,
      loading: loadingDepartment,
    } = useDepartments()

    // alerts
    const showAlert = ref(false)
    const alertType = ref('success')

    const addNewMaterial = () => {
      wasteReportForm.value.waste_materials.push({
        quantity: 1,
        unit_price: 0,
        description: null,
        unit_issue: null,
        sales_or_number: null,
        sales_amount: null,
      })
    }

    const refreshAlert = () => {
      showAlert.value = false
      alertType.value = 'success'
    }

    const exportPdf = () => {
      window.location.href = `${process.env.VUE_APP_SERVER_URL}/export-waste-report/${wasteReportForm.value.id}`
    }

    const save = () => {
      refreshAlert()
      saving.value = true
      if (wasteReportForm.value.id) {
        axios
          .put(`/waste-reports/${wasteReportForm.value.id}`, wasteReportForm.value)
          .then(() => {
            saving.value = false
            router.push({ name: 'waste-reports' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      } else {
        axios
          .post('/waste-reports', wasteReportForm.value)
          .then(() => {
            saving.value = false
            router.push({ name: 'waste-reports' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      }
    }

    const fetchDepartments = () => {
      getDepartments({ search: searchDepartment.value })
    }

    if (router.currentRoute.params.id) {
      saving.value = true
      getWasteReport(router.currentRoute.params.id).then(response => {
        const { data } = response

        wasteReportForm.value = data
        wasteReportForm.value.waste_materials = data.waste_materials

        const department = {
          name: data.department_name,
          id: data.department_id,
        }
        departmentOptions.value = data.department_id ? [department] : []
        wasteReportForm.value.department = department

        saving.value = false
      })
    }

    return {
      // alerts
      showAlert,
      alertType,
      saving,

      // variables
      wasteReportForm,
      vehicleTab,
      errors,
      reportDatePicker,
      completionDatePicker,

      // functions
      save,
      addNewMaterial,
      exportPdf,

      // useWasteReport
      getWasteReport,

      // useDepartments
      departmentOptions,
      fetchDepartments,
      searchDepartment,
      loadingDepartment,

      resetForm,

      // icons
      icons: {
        mdiExportVariant,
        mdiCalendarCheckOutline,
        mdiSpeedometer,
        mdiCogOutline,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';
.app-invoice-editable {
  .header-inputs {
    width: 122px;
  }

  .add-services-form {
    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-services-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
