import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useWasteReports() {
  const wasteReports = ref([])
  const wasteReport = ref({})
  const loading = ref(false)
  const searchWasteReport = ref(null)

  const getWasteReports = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .post('/waste-reports/filter', params)
        .then(response => {
          const { data } = response.data
          wasteReports.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getWasteReport = id => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get(`/waste-reports/${id}`)
        .then(response => {
          const { data } = response
          wasteReport.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    // variables
    loading,
    searchWasteReport,
    wasteReports,
    wasteReport,

    // functions
    getWasteReports,
    getWasteReport,
  }
}
